.van-popover {
  position: absolute;
  overflow: visible;
  background-color: transparent;
  transition: opacity 0.15s, transform 0.15s;
}
.van-popover__wrapper {
  display: inline-block;
}
.van-popover__arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
}
.van-popover__content {
  overflow: hidden;
  border-radius: 8px;
}
.van-popover__action {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 128px;
  height: 44px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}
.van-popover__action:last-child .van-popover__action-text::after {
  display: none;
}
.van-popover__action-text {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.van-popover__action-icon {
  margin-right: 8px;
  font-size: 20px;
}
.van-popover__action--with-icon .van-popover__action-text {
  justify-content: flex-start;
}
.van-popover[data-popper-placement^='top'] .van-popover__arrow {
  bottom: 0;
  border-top-color: currentColor;
  border-bottom-width: 0;
  transform: translate(-50%, 100%);
}
.van-popover[data-popper-placement='top'] {
  transform-origin: 50% 100%;
}
.van-popover[data-popper-placement='top'] .van-popover__arrow {
  left: 50%;
}
.van-popover[data-popper-placement='top-start'] {
  transform-origin: 0 100%;
}
.van-popover[data-popper-placement='top-start'] .van-popover__arrow {
  left: 16px;
}
.van-popover[data-popper-placement='top-end'] {
  transform-origin: 100% 100%;
}
.van-popover[data-popper-placement='top-end'] .van-popover__arrow {
  right: 16px;
}
.van-popover[data-popper-placement^='left'] .van-popover__arrow {
  right: 0;
  border-right-width: 0;
  border-left-color: currentColor;
  transform: translate(100%, -50%);
}
.van-popover[data-popper-placement='left'] {
  transform-origin: 100% 50%;
}
.van-popover[data-popper-placement='left'] .van-popover__arrow {
  top: 50%;
}
.van-popover[data-popper-placement='left-start'] {
  transform-origin: 100% 0;
}
.van-popover[data-popper-placement='left-start'] .van-popover__arrow {
  top: 16px;
}
.van-popover[data-popper-placement='left-end'] {
  transform-origin: 100% 100%;
}
.van-popover[data-popper-placement='left-end'] .van-popover__arrow {
  bottom: 16px;
}
.van-popover[data-popper-placement^='right'] .van-popover__arrow {
  left: 0;
  border-right-color: currentColor;
  border-left-width: 0;
  transform: translate(-100%, -50%);
}
.van-popover[data-popper-placement='right'] {
  transform-origin: 0 50%;
}
.van-popover[data-popper-placement='right'] .van-popover__arrow {
  top: 50%;
}
.van-popover[data-popper-placement='right-start'] {
  transform-origin: 0 0;
}
.van-popover[data-popper-placement='right-start'] .van-popover__arrow {
  top: 16px;
}
.van-popover[data-popper-placement='right-end'] {
  transform-origin: 0 100%;
}
.van-popover[data-popper-placement='right-end'] .van-popover__arrow {
  bottom: 16px;
}
.van-popover[data-popper-placement^='bottom'] .van-popover__arrow {
  top: 0;
  border-top-width: 0;
  border-bottom-color: currentColor;
  transform: translate(-50%, -100%);
}
.van-popover[data-popper-placement='bottom'] {
  transform-origin: 50% 0;
}
.van-popover[data-popper-placement='bottom'] .van-popover__arrow {
  left: 50%;
}
.van-popover[data-popper-placement='bottom-start'] {
  transform-origin: 0 0;
}
.van-popover[data-popper-placement='bottom-start'] .van-popover__arrow {
  left: 16px;
}
.van-popover[data-popper-placement='bottom-end'] {
  transform-origin: 100% 0;
}
.van-popover[data-popper-placement='bottom-end'] .van-popover__arrow {
  right: 16px;
}
.van-popover--light {
  color: #323233;
}
.van-popover--light .van-popover__content {
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(50, 50, 51, 0.12);
}
.van-popover--light .van-popover__arrow {
  color: #fff;
}
.van-popover--light .van-popover__action:active {
  background-color: #f2f3f5;
}
.van-popover--light .van-popover__action--disabled {
  color: #c8c9cc;
  cursor: not-allowed;
}
.van-popover--light .van-popover__action--disabled:active {
  background-color: transparent;
}
.van-popover--dark {
  color: #fff;
}
.van-popover--dark .van-popover__content {
  background-color: #4a4a4a;
}
.van-popover--dark .van-popover__arrow {
  color: #4a4a4a;
}
.van-popover--dark .van-popover__action:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.van-popover--dark .van-popover__action--disabled {
  color: #969799;
}
.van-popover--dark .van-popover__action--disabled:active {
  background-color: transparent;
}
.van-popover--dark .van-popover__action-text::after {
  border-color: #646566;
}
.van-popover-zoom-enter,
.van-popover-zoom-leave-active {
  transform: scale(0.8);
  opacity: 0;
}
.van-popover-zoom-enter-active {
  transition-timing-function: ease-out;
}
.van-popover-zoom-leave-active {
  transition-timing-function: ease-in;
}
