/**
 * Entry of basic styles
 */
html {
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
}
a {
  text-decoration: none;
}
input,
button,
textarea {
  color: inherit;
  font: inherit;
}
a:focus,
input:focus,
button:focus,
textarea:focus,
[class*='van-']:focus {
  outline: none;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.van-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.van-multi-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.van-multi-ellipsis--l3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.van-clearfix::after {
  display: table;
  clear: both;
  content: '';
}
[class*='van-hairline']::after {
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
  border: 0 solid #ebedf0;
  transform: scale(0.5);
}
.van-hairline,
.van-hairline--top,
.van-hairline--left,
.van-hairline--right,
.van-hairline--bottom,
.van-hairline--surround,
.van-hairline--top-bottom {
  position: relative;
}
.van-hairline--top::after {
  border-top-width: 1px;
}
.van-hairline--left::after {
  border-left-width: 1px;
}
.van-hairline--right::after {
  border-right-width: 1px;
}
.van-hairline--bottom::after {
  border-bottom-width: 1px;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 1px 0;
}
.van-hairline--surround::after {
  border-width: 1px;
}
@keyframes van-slide-up-enter {
  from {
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes van-slide-up-leave {
  to {
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes van-slide-down-enter {
  from {
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes van-slide-down-leave {
  to {
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes van-slide-left-enter {
  from {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes van-slide-left-leave {
  to {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes van-slide-right-enter {
  from {
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes van-slide-right-leave {
  to {
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes van-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes van-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes van-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.van-fade-enter-active {
  animation: 0.3s van-fade-in both ease-out;
}
.van-fade-leave-active {
  animation: 0.3s van-fade-out both ease-in;
}
.van-slide-up-enter-active {
  animation: van-slide-up-enter 0.3s both ease-out;
}
.van-slide-up-leave-active {
  animation: van-slide-up-leave 0.3s both ease-in;
}
.van-slide-down-enter-active {
  animation: van-slide-down-enter 0.3s both ease-out;
}
.van-slide-down-leave-active {
  animation: van-slide-down-leave 0.3s both ease-in;
}
.van-slide-left-enter-active {
  animation: van-slide-left-enter 0.3s both ease-out;
}
.van-slide-left-leave-active {
  animation: van-slide-left-leave 0.3s both ease-in;
}
.van-slide-right-enter-active {
  animation: van-slide-right-enter 0.3s both ease-out;
}
.van-slide-right-leave-active {
  animation: van-slide-right-leave 0.3s both ease-in;
}
